import React from "react";

const Donate = () => {
  return (
    <div className="main-content">
      <h3> Donations</h3>
      <p>
      Support women who have been victims of the Tigray genocidal war by making a donation to help them rebuild their lives and support their families. Whether you contribute $5, $10, $50, $100, or any amount, your generosity makes a meaningful difference. We are deeply grateful for your support and thank you for your kindness..{" "}
      </p>

      <p>You can donate using any of the following methods.</p>
      
      <p><b>Online Payment System</b></p>
      <ul>
        <li>
      <b>Zeffy</b> is our payment processing system for donations. Please select an amount from the options below or enter your desired amount in the space provided.
        </li>
      </ul>
    
      <div className="zeffy">
        <iframe
          title="Donation form powered by Zeffy"
          src="https://www.zeffy.com/embed/donation-form/3b90db61-ecf2-4247-a3d7-f7c48691953f"
          allowpaymentrequest
          allowTransparency="true"
        ></iframe>
      </div>

      <ul>
        <li>
          <b>Zelle Service</b>: Use Rediet's email address <b>rediet.tw@gmail.com</b>.
          This transfer service is tied to our account with Bank of America.
          Hence, it will be deposited in the account shown below.{" "}
        </li>
        <br />
        <li>
          Direct deposit to Rediet's{" "}
          <b>Bank of America Account: Account Number: 435048377784</b>
        </li>
        <br />
        <li>
          Mail check to Rediet's Address:
          <br />
          <ul>3826 Koval Lane</ul>
          <ul>Woodbridge, VA 22192</ul>
          <ul>USA</ul>
        </li>
      </ul>
    </div>
  );
};

export default Donate;
