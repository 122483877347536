import React from "react";
import { NavLink } from "react-router-dom";

import USA01 from "../images/usa/MothersDayArlingtonVA2022_05_28_1.jpg";
import USA02 from "../images/usa/MothersDayArlingtonVA2022_05_28_2.jpg";
import USA03 from "../images/usa/MothersDayArlingtonVA2022_05_28_4.jpg";
import USA04 from "../images/usa/MothersDayArlingtonVA2022_05_28_5.jpg";
import USA05 from "../images/usa/Tigray_festival_event_July_2023.jpg";
import USA06 from "../images/usa/usa_IMG_0019.jpg";

// 2024-10-13 - Arlington VA-picnic picuters
import USA_Arlington_VA_picnic_01 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-01.JPG";
import USA_Arlington_VA_picnic_02 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-02.JPG";
import USA_Arlington_VA_picnic_03 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-03_appreciated.JPG";
import USA_Arlington_VA_picnic_04 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-04.JPG";

import USA_Arlington_VA_picnic_05 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-05.JPG";

import USA_Arlington_VA_picnic_06 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-06.JPG";

import USA_Arlington_VA_picnic_07 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-07_refreshments.JPG";

import USA_Arlington_VA_picnic_08 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-08_cooking_team.JPG";

// import USA_Arlington_VA_picnic_09 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-09-the-cooking-team.JPG";

import USA_Arlington_VA_picnic_10 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-10-board-members-in-action.JPG";

import USA_Arlington_VA_picnic_11 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-11-from-old-to-young.JPG";

import USA_Arlington_VA_picnic_12 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-12-refreshments.JPG";

import USA_Arlington_VA_picnic_13 from "../images/usa/2024_10_13_Picnic_Arlington_VA/2024-10-13-Rediet-picnic-13.JPG";

import USA_Arlington_VA_picnic_14 from "../images/usa/2024_10_13_Picnic_Arlington_VA/certificatesOfAppreciation.JPG";

// Texas
import USA_TX_01 from "../images/usa/TX-USA-001.jpg";
import USA_TX_02 from "../images/usa/TX-USA-002.jpg";
import USA_TX_03 from "../images/usa/TX-USA-003.jpg";

const PhotosUSA = () => {
  return (
    <div className="main-content">
      <h4>
        Anyone who has photos related to Rediet activities taken anywhere in USA
        (Virginia, MaryLand, Texas, DC, Illinois, etc.), please share.
      </h4>
      <p>
        You can store them in Rediet's google drive or email them to webmaster
        at{" "}
        <NavLink href=" mailto: admin@rediet4tw.org">
          admin@rediet4tw.org
        </NavLink>
      </p>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA01}
              alt="Rediet Fundraiser ECDC Meeting, Arlington VA"
            />
            <figcaption>
              Mother's day fundraiser meeting in ECDC, Arlington VA, 2022-05-28
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA02}
              alt="Rediet Fundraiser ECDC Meeting, Arlington VA"
            />
            <figcaption>
              Mother's day fundraiser meeting in ECDC, Arlington VA, 2022-05-28
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA03}
              alt="Rediet Fundraiser ECDC Meeting, Arlington VA"
            />
            <figcaption>
              Mother's day fundraiser meeting in ECDC, Arlington VA, 2022-05-28
            </figcaption>
          </figure>
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA04}
              alt="Rediet Fundraiser ECDC Meeting, Arlington VA"
            />
            <figcaption>
              Mother's day fundraiser meeting in ECDC, Arlington VA, 2022-05-28.
              The board chairperson, W/ro Mamit making a speech during the
              meeting.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA05}
              alt="Rediet Fundraiser ECDC Meeting, Arlington VA"
            />
            <figcaption>
              Selam Tesfay and Eldana Kibrom serving refereshments in
              Washington, DC.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img className="responsive" src={USA06} alt="Rediet Fundraiser " />
            <figcaption>Rediet Fundraiser - Maryland</figcaption>
          </figure>
        </div>
      </div>

      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_01}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_02}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_03}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
      </div>

      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_04}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_05}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_06}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_07}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_08}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_13}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>

        {/* <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_09}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div> */}
      </div>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_10}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_11}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_12}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
      </div>

      <div className="gallery_group">
        {/* <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_13}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div> */}
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_14}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>Rediet Pinic - 2024-10-13 - Arlington VA</figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          {/* <figure>
            <img
              className="responsive"
              src={USA_Arlington_VA_picnic_15}
              alt="Rediet Pinic - 2024-10-13 - Arlington VA"
            />
            <figcaption>
            Rediet Pinic - 2024-10-13 - Arlington VA
            </figcaption>
          </figure> */}
        </div>
      </div>

      {/* Pictures from Houston, TX */}
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_TX_01}
              alt="Our members from Houston TX"
            />
            <figcaption>
              Some of our Rediet members from Houston, Texas, 2024
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_TX_02}
              alt="Our members from Houston TX"
            />
            <figcaption>
              Some of our Rediet members from Houston, Texas, 2024
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={USA_TX_03}
              alt="Our members from Houston TX"
            />
            <figcaption>
              Some of our Rediet members from Houston, Texas, 2024
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default PhotosUSA;
